@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #227aff;
  /* primary: "#227AFF",
      light: "#EFF5FF",
      accent: "#A06D3A", */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Outfit", sans-serif !important;
  width: 100%;
  overflow-x: hidden;
}
* {
  outline: none !important;
  font-family: "Outfit", sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
center {
  @apply flex items-center justify-center;
}
.animate {
  @apply transform transition duration-300 ease-in-out;
}
button {
  @apply py-1 !important;
}
main {
  @apply container mx-auto px-4 lg:px-8 py-4 overflow-x-hidden;
}

.bg-1 {
  background-image: url("./assets/bg1.png");
  background-repeat: no-repeat;
  @apply bg-primary;
  background-position: center;
  background-size: cover;
}
/* #TYPO */
h1 {
  @apply text-4xl lg:text-5xl xl:text-6xl font-medium;
}
h2 {
  @apply text-3xl font-semibold;
}
h4 {
  @apply text-xl font-semibold;
}
h6 {
  @apply text-base font-semibold;
}
p {
  @apply text-base font-normal text-gray-600;
}

input {
  @apply bg-white  placeholder:text-gray-300 !important;
}
.ff-email-widget {
  @apply bg-gray-100 !important;
}
.ff-powered-img img {
  @apply hidden !important;
}
.switch-check input {
  background-color: #227aff !important;
}
.switch-uncheck input {
  background-color: #7c7c7c !important;
}

